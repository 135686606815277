export const PERMISSIONS_DICTIONARY = {
  domains: {
    name: 'Domains',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  sites: {
    name: 'Sites',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  users: {
    name: 'Users',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  numbers: {
    name: 'Numbers',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  trunks: {
    name: 'Trunks',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
  wizards: {
    name: 'Wizards',
    permissions: { read: 'View', add: 'Add', edit: 'Edit', delete: 'Delete' },
  },
};
