import {
  RoleEntityPermissions,
  SecurityProfile,
  SecurityProfileResponseEntity,
  SecurityProfileResponsePermission,
} from '../../models/rbac-models';

export function interpretPermissions(
  securityProfileResponse: SecurityProfileResponseEntity[]
): SecurityProfile {
  const securityProfile: SecurityProfile = {};
  securityProfileResponse.forEach((entity: SecurityProfileResponseEntity) => {
    if (entity.permissions?.length > 0) {
      const entityProfile: RoleEntityPermissions = {};
      entity.permissions.forEach(
        (permission: SecurityProfileResponsePermission) => {
          entityProfile[permission.name] = true;
        }
      );
      securityProfile[entity.entityName] = entityProfile;
    }
  });
  return securityProfile;
}

export function isEntityEnabled(
  entities: SecurityProfile,
  entity: string
): boolean {
  const permissions = entities[entity];
  if (permissions) {
    for (const permission in permissions) {
      if (permissions[permission]) return true;
    }
  }
  return false;
}

export function isPermissionEnabled(
  entities: SecurityProfile,
  entity: string,
  permission: string
): boolean {
  const permissions = entities[entity];
  if (permissions) {
    for (const entityPermission in permissions) {
      if (permission === entityPermission) {
        return !!permissions[entityPermission];
      }
    }
  }
  return false;
}

export function toPascalCase(value: string): string {
  return `${value}`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    .replace(new RegExp(/\w/), (s) => s.toUpperCase());
}
