import {  CreateDomainResponse, CreatePhonenumberResponse, CreateUserResponse, ErrorResponse, PostDomainsByDomainSitesResponse } from "../netsapiens";
import { DomainConfig, NetsapiensPhoneNumber, NetsapiensSite, NetsapiensUser } from "./voice-models";

export interface WizardInstance {
  wizardId?: string;
  domainId: string;
  reseller: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  progress: WizardProgress;
  domain?: DomainConfig;
  numbers?: NetsapiensPhoneNumber[];
  sites?: NetsapiensSite[];
  users?: NetsapiensUser[];
  steps?: Steps;
}

export interface WizardProgress {
  progress: number;
  verified: boolean;
  domain: boolean;
  numbers: boolean;
  sites: boolean;
  users: boolean;
}

export enum WizardConfig {
  domain = 'domain',
  numbers = 'numbers',
  sites = 'sites',
  users = 'users',
}

export enum WizardStepKey {
  domain = 'domain',
  numbers = 'numbers',
  sites = 'sites',
  users = 'users',
  verify = 'verify',
  provision = 'provision',
}

export interface WizardStep {
  wizardKey: WizardStepKey;
  title: string;
  icon: string;
  touched?: boolean;
  valid?: boolean;
  disabled?: boolean;
  nextDisabled?: boolean;
  backDisabled?: boolean;
}

export interface SerializedWizardJSONProperties {
  progress: string;
  domain?: string;
  numbers?: string;
  sites?: string;
  users?: string;
}


export interface ErrorMap {
  [key: string]: ErrorResponse | ErrorResponse[];
}

export interface Steps {
  completed: number;
  total: number;
}


export type ErrorTrace  = Record<string, (Data & { error: ErrorResponse }) | (Data & { error: ErrorResponse })[]>
export type Data = DomainConfig | NetsapiensSite | NetsapiensUser | NetsapiensPhoneNumber;
export type Return = CreateUserResponse | PostDomainsByDomainSitesResponse | CreateDomainResponse| CreatePhonenumberResponse |  {error: ErrorResponse};