export const VALID_SITE_NAME_EXPRESSION = '[a-zA-Z0-9 ._-]*';

export const VALID_PHONE_NUMBER_EXPRESSION = '[0-9]*';

export const VALID_US_TIMEZONES: string[] = [
  'US/Alaska',
  'US/Aleutian',
  'US/Arizona',
  'US/Central',
  'US/East-Indiana',
  'US/Eastern',
  'US/Hawaii',
  'US/Indiana-Starke',
  'US/Michigan',
  'US/Mountain',
  'US/Pacific',
  'US/Samoa',
];

export const VALID_USER_SCOPES: string[] = [
  'Basic User',
  'Simple User',
  'Advanced User',
  'Call Center Agent',
  'Site Manager',
  'Call Center Supervisor',
  'Office Manager',
  'Reseller',
  'Super User',
  'NDP',
  'No Portal',
];

export class NetsapiensReseller {
  reseller!: string;
  description!: string;
}

export class NetsapiensDomain {
  domain!: string;
  domainType!: string;
  countUsers!: number;
  description!: string;
  activeCalls!: number;
  externalCalls!: number;
  showDetails?: boolean;
}

export interface NetsapiensUser {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  site?: string;
  scope: string;
  phoneNumber?: string;
  domain?: string;
  extension?: number;
  validationMessages?: string[];
}

export interface NetsapiensSite {
  timezone: string;
  areaCode: number;
  domain?: string;
  site: string;
  validationMessages?: string[];
}

export interface NetsapiensPhoneNumber {
  domain?: string;
  phoneNumber: string;
  enabled: string;
  dialRuleApplication?: string;
  dialRuleDescription?: string;
  dialRuleParameter?: string;
  dialRuleTranslationDestinationHost?: string;
  dialRuleTranslationDestinationUser?: string;
  dialRuleTranslationSourceName?: string;
  userName?: string;
  validationMessages?: string[];
}

export class KeyValueItem {
  key!: string;
  value!: string | number | boolean;
}

export interface DomainConfig {
  edited?: boolean;
  valid?: boolean;
  domain: string;
  reseller: string;
  domainDescription: string;
  domainSenderEmail: string;
  residential: string;
  status: string;
  sso: string;
  dialPermission: string;
  timezone: string;
  areaCode: number;
  callerName: string;
  callerId: number;
  domainRMOH: string;
  voicemail: boolean;
  server: string;
}

export type WizardConfigData =
  | DomainConfig
  | NetsapiensSite[]
  | NetsapiensUser[]
  | NetsapiensPhoneNumber[]
  | undefined;
