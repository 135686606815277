import { MsalService } from '@azure/msal-angular';
import { User } from '../models/user.model';
import { Injectable } from '@angular/core';
import {
  interpretPermissions,
  isEntityEnabled,
  isPermissionEnabled,
  SecurityProfile,
  SecurityProfileResponseEntity,
} from '@voice/utils';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SecurityService {
  private currentUser!: User;
  private securityProfile!: SecurityProfile;

  constructor(
    private readonly authService: MsalService,
    private readonly http: HttpClient
  ) {
    this.getCurrentUser().then();
  }

  public getCurrentUser(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      if (this.currentUser) {
        resolve(this.currentUser);
        return;
      }
      const account =
        this.authService.instance.getActiveAccount()?.idTokenClaims;
      if (account) {
        this.currentUser = {
          id: account?.oid,
          email: account?.preferred_username,
          name: account?.name,
          roles: account?.roles,
        } as User;
        resolve(this.currentUser);
      } else {
        reject(new Error('Not logged in'));
      }
    });
  }

  public async getSecurityProfile(): Promise<SecurityProfile> {
    return new Promise<SecurityProfile>((resolve, reject) => {
      if (this.securityProfile) {
        resolve(this.securityProfile);
        return;
      }
      if (this.currentUser) {
        const url = `${environment.rbacAPIConfig.uri}/security-profile`;
        this.http.get<SecurityProfileResponseEntity[]>(url).subscribe({
          next: (data) => {
            this.securityProfile = interpretPermissions(data);
            resolve(this.securityProfile);
          },
        });
      } else {
        reject(new Error('Not logged in'));
      }
    });
  }

  public hasEntityAssigned(entity: string): boolean {
    if (!this.securityProfile) return false;
    return isEntityEnabled(this.securityProfile, entity);
  }

  public hasPermissionAssigned(entity: string, permission: string): boolean {
    if (!this.securityProfile) return false;
    return isPermissionEnabled(this.securityProfile, entity, permission);
  }
}
