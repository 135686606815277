export const isValidUSAPhoneNumber = (phoneNumber: string): boolean => {
  // Remove non-digit characters
  const cleanedNumber: string = phoneNumber.replace(/\D/g, '');

  // Check if the number is 10 or 11 digits long (with optional country code)
  if (cleanedNumber.length < 10 || cleanedNumber.length > 11) {
    return false;
  }

  // Check if the first digit is 1 (optional country code)
  if (cleanedNumber.length === 11 && cleanedNumber[0] !== '1') {
    return false;
  }

  // Check if the area code starts with a digit 2-9
  const areaCode =
    cleanedNumber.length === 11 ? cleanedNumber[1] : cleanedNumber[0];
  if (parseInt(areaCode) < 2) {
    return false;
  }

  // Check if the exchange code starts with a digit 2-9
  const exchangeCode =
    cleanedNumber.length === 11 ? cleanedNumber[4] : cleanedNumber[3];
  if (parseInt(exchangeCode) < 2) {
    return false;
  }

  return true;
};
